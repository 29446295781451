const labels = [
  {
    name: 'Car Type',
    key: 'type',
    key2: 'name',
  },
  {
    name: 'Brand',
    key: 'brand',
    key2: 'name',
  },
  {
    name: 'Model',
    key: 'model',
    key2: 'name',
  },
  {
    name: 'Sub Model',
    key: 'series',
    key2: 'name',
  },
  {
    name: 'Year Model',
    key: 'year',
  },
  {
    name: 'Seats',
    key: 'seat',
  },
  {
    name: 'Doors',
    key: 'door',
  },
  {
    name: 'Color',
    key: 'color',
    key2: 'name',
  },
  {
    name: 'Transmission',
    key: 'transmission',
  },
  {
    name: 'Fuel Types',
    key: 'feuls',
    key2: 'name',
  },
  {
    name: 'Options',
    key: 'carOptions',
  },
  {
    name: 'GPS Types',
    key: '',
    key2: 'name',
  },
  {
    name: 'Plate License',
    key: 'plate',
  },
  {
    name: 'License Expire Date',
    key: 'licenceExpireDate',
  },
  {
    name: 'Compulsory Insurance Expire Date',
    key: 'compulsoryInsuranceExpireDate',
  },
  {
    name: 'Insurance ExpireDate',
    key: 'insuranceExpireDate',
  },
  {
    name: 'Price Per Day (THB)',
    key: 'price',
    key2: 'perDay',
  },
  {
    name: 'Price Per Month (THB)',
    key: 'price',
    key2: 'perMonth',
  },
  {
    name: 'Owner Name',
    key: 'owner',
    key2: 'name',
  },
  {
    name: 'Store Name',
    key: '',
  },
];

export default labels;
