
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { Actions } from '@/store/enums/StoreEnums';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import carHeader from '@/core/data/car/partnerCarHeader';
import labels from '@/core/data/car/partnerCarLabel';
import ownerLabels from '@/core/data/partner/ownerLabel';
import CarDetailsCard from '@/components/cards/CarDetailsCard.vue';
import moment from 'moment';

export default {
  name: 'PartnerVerifyWildcard',
  components: {
    Datatable,
    CarDetailsCard,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { id } = route.params;
    const data = ref<any>([]);
    const pictureLabels = ['รูปถ่ายใบหน้า', 'บัตรประชาชน', 'สำเนาบุ๊คแบงค์'];
    const personalDetails = ref<any>([]);
    const stats = ref<any>([]);
    const carData = ref([]);
    const documentHeader = [
      { name: 'Image', key: 'image', sortable: false },
      { name: 'Description', key: 'title', sortable: false },
    ];
    const documentData = ref<any>([]);
    const imageData = ref<any>([]);
    const currentImage = ref('');
    const timeFormat = [
      'licenceExpireDate',
      'insuranceExpireDate',
      'compulsoryInsuranceExpireDate',
    ];

    onMounted(async () => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

      const res = await ApiService.get(`/car/${id}`);
      data.value = res.data.data;

      document.title = data.value.plate || 'Partner Car Details';

      const carRes = await ApiService.get(`/car`);
      carData.value = carRes.data.datas;

      personalDetails.value = [
        {
          name: data.value.role,
          svg: 'media/icons/duotune/communication/com006.svg',
        },
        {
          name: data.value.address,
          svg: 'media/icons/duotune/general/gen018.svg',
        },
        {
          name: data.value.phone,
          svg: 'media/icons/duotune/electronics/elc002.svg',
        },
        {
          name: data.value.email,
          svg: 'media/icons/duotune/communication/com011.svg',
        },
      ];

      documentData.value = data.value.verifyDocuments;
      imageData.value = data.value.images;

      stats.value = [{ number: data.value, label: 'Total Orders' }];

      setCurrentPageBreadcrumbs(
        'Partner Car',
        [{ name: 'Car Lists', path: '/partner-car/lists' }, 'Car Details'],
        { name: 'View Verification', path: `/partner-car/verify/${id}` }
      );

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
    });

    const changeImage = (image) => {
      currentImage.value = image;
    };

    return {
      labels,
      data,
      pictureLabels,
      stats,
      personalDetails,
      carHeader,
      carData,
      documentHeader,
      documentData,
      imageData,
      currentImage,
      changeImage,
      ownerLabels,
      timeFormat,
      moment,
    };
  },
};
