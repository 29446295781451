
import { ref, watch } from 'vue';
import { numberFormat } from '@/utils/helper/format';

export default {
  name: 'PartnerDetailsCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
    isCar: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const personalDetails = ref<any>([]);
    const stats = ref<any>([]);
    const placeholderImage = props.isCar
      ? 'media/images/mystery+car.jpg'
      : 'media/images/steam-question-mark-icon-29.jpg';

    const changeImage = (image) => {
      emit('change-image', image);
    };

    watch(
      () => (props.isCar ? props.data : props.data.role),
      () => {
        personalDetails.value = personalDetails.value = [
          {
            name: props.data.customOwnerInfo.name,
            svg: 'media/icons/duotune/communication/com006.svg',
          },
          {
            name: props.data.customOwnerInfo?.province?.name || '-',
            svg: 'media/icons/duotune/general/gen018.svg',
          },
          {
            name: props.data.customOwnerInfo.phone || '-',
            svg: 'media/icons/duotune/electronics/elc002.svg',
          },
          {
            name: props.data.customOwnerInfo.email,
            svg: 'media/icons/duotune/communication/com011.svg',
          },
        ];
        if (props.data.statistic) {
          stats.value = [
            {
              number: numberFormat(props.data.statistic.rentCount),
              label: 'Rent Count',
            },
            {
              number: numberFormat(props.data.statistic.rating),
              label: 'Rating',
            },
          ];
        }
      }
    );

    return { stats, personalDetails, changeImage, placeholderImage };
  },
};
