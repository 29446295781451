const labels = [
  {
    name: 'คำนำหน้าชื่อ / Honorific',
    key: '',
  },
  {
    name: 'ชื่อ / First Name',
    key: 'name',
  },
  {
    name: 'สกุล / Last Name',
    key: 'name',
  },
  {
    name: 'เพศ / Gender',
    key: 'gender',
  },
  {
    name: 'วัดเกิด / Dath of Birth',
    key: 'birthdate',
  },
  {
    name: 'หมายเลขบัตรประชาชน / National ID',
    key: 'personalId',
  },
  {
    name: 'ที่อยู่ / Address',
    key: 'address',
  },
  {
    name: 'จังหวัด / Province',
    key: 'province',
  },
  {
    name: 'อำเภอ / District',
    key: 'district',
  },
  {
    name: 'ตำบล / Sub-district',
    key: 'subDistrict',
  },
  {
    name: 'รหัสไปรษณีย์ / Postal Code',
    key: 'postNumber',
  },
  {
    name: 'เบอร์โทรศัพท์ / Phone Number',
    key: 'phone',
  },
  {
    name: 'อีเมล / Email',
    key: 'email',
  },
];

export default labels;
